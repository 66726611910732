<template>
  <v-container fluid>
    <v-row>
      <v-container fluid><h3 class="page-title">AML search</h3></v-container>
      <Search />
    </v-row>

    <v-row v-if="error" justify="center">
      <RequestErrorMessage :error="error" />
    </v-row>

    <v-row v-else-if="searchResult.length > 0" justify="center">
      <Result :items="searchResult" />
    </v-row>

    <v-row v-else-if="searchResult.length === 0 && hasLoadedOnce" justify="center">
      <NoResultsMessage />
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Search from "@/components/aml/search/Search";
import Result from "@/components/aml/result/Result";
import NoResultsMessage from "@/components/common/NoResultsMessage";
import RequestErrorMessage from "@/components/common/RequestErrorMessage";
import { SEARCH_RESET } from "@/store/actions/search";

const { mapGetters, mapActions } = createNamespacedHelpers("search");

export default {
  components: {
    Search,
    Result,
    NoResultsMessage,
    RequestErrorMessage,
  },
  computed: {
    ...mapGetters(["searchResult", "hasLoadedOnce", "error"]),
  },
  methods: {
    ...mapActions({
      reset: SEARCH_RESET,
    }),
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>
