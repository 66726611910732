<template>
  <v-sheet class="text-center pa-4">
    <v-col>
      <v-row class="subtitle-1"> Refine your search: </v-row>
      <v-row>
        <v-col align-self="center">
          <v-switch label="Individuals"
                    v-model="value.isIndividual"
                    color="primary"
          />
          <v-switch label="Companies"
                    v-model="value.isCompany"
                    color="primary"
          />
          <v-switch label="Initials"
                    v-model="value.isInitials"
                    color="primary"
          />
        </v-col>

        <v-col align-self="center" cols="4">
          <v-autocomplete label="By years"
                          :items="years"
                          v-model="value.selectedBirthYears"
                          multiple dense small-chips deletable-chips
          />
          <v-text-field label="By date (or part of date) in format YYYY-MM-DD"
                        dense
                        v-model="value.dates"
          >

          </v-text-field>
          <v-autocomplete label="Countries"
                          :items="countryList"
                          v-model="value.selectedCountries"
                          multiple dense small-chips deletable-chips
          />
          <v-autocomplete label="Data sources"
                          :items="dataSourceList"
                          v-model="value.selectedDataSources"
                          multiple dense small-chips deletable-chips
          />
        </v-col>

        <v-col align-self="center">
          <v-slider label="Word alignment:"
                    v-model="value.threshold"
                    :min="0" :max="1"
                    ticks
                    step="0.05"
                    thumb-label="always"
                    thumb-size="25"
                    class="pa-0"

          >
            <template v-slot:thumb-label="{value}">{{ Math.round(value * 100) / 100 }} </template>
          </v-slider>
          <v-slider label="Phonemix™ threshold:"
                    v-model="value.mlThreshold"
                    :min="0" :max="1"
                    ticks
                    step="0.05"
                    thumb-label="always"
                    thumb-size="25"
                    class="pa-0"

          >
            <template v-slot:thumb-label="{value}">{{ Math.round(value * 100) / 100 }} </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            outlined
            color="secondary"
            @click="$emit('toggle-options')"
          >
            Hide
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import { lifeYearRange } from '@/utils/dates';

export default {
  props: {
    value: Object,
  },
  computed: {
    ...mapGetters(['dataSourceList', 'countryList']),
    years() {
      return lifeYearRange();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>
