<template>
  <v-alert v-if="error" style="width: 100%" type="error" outlined>
    Invalid request: {{ formattedErrorMesssage }}
  </v-alert>
</template>

<script>
export default {
  props: {
    error: Error,
  },
  data() {
    return {
      errorCodesMessages: {
        401: 'Unauthorized access. Try to login again',
        403: 'Unauthorized access. Try to login again',
        422: 'Unprocessable entity. Enter a valid query',
        500: 'A server error has occured. Validated your request and destination',
      },
    };
  },
  computed: {
    formattedErrorMesssage() {
      const errorStatusRegex = /[0-9]+/;
      const errorStatus = parseInt(this.error.message.match(errorStatusRegex), 10);

      const msg = this.errorCodesMessages[errorStatus] || 'An unknown error has occured';

      return msg;
    },
  },
};
</script>
