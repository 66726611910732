<template>
  <v-form @submit.prevent="$emit('search')">
    <v-text-field
      label="Input your search here..."
      :value="value"
      @input="$emit('input', $event)"
      clearable
      solo
      @click:clear="this.searchReset"
      full-width
      class="pl-md-10 pr-md-10"
    >
      <template v-slot:append-outer>
        <v-icon large @click="$emit('search')"> search </v-icon>
        <v-icon large @click="$emit('toggle-options')"> filter_list </v-icon>
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { SEARCH_RESET } from '@/store/actions/search';

const { mapActions } = createNamespacedHelpers('search');

export default {
  props: {
    value: String,
  },
  methods: {
    ...mapActions({
      searchReset: SEARCH_RESET,
    }),
  },
};
</script>

<style>
</style>
