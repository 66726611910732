<template>
  <v-row>
    <v-col>
      <EntitiesList
        :items="items"
        :headers="computedHeaders"
        :loading="isLoading"
        :item-data-slots="itemDataSlots"
      >
        <template v-slot:top>
          <load-indicator v-model="isLoading"/>
          <v-row v-if="minThreshold && maxThreshold && (minThreshold !== maxThreshold)" class="mt-5 ml-7">
            <v-col cols="4" align="center" >
              <v-slider label="Threshold:"
                        v-model="currentThreshold"
                        :min="0" :max="1"
                        ticks
                        step="0.01"
                        thumb-label="always"
                        thumb-size="25"
              >
                <template v-slot:thumb-label="{value}">{{ Math.round(value * 100) / 100 }}</template>
              </v-slider>
            </v-col>
            <v-col>
              <v-switch label="Show with images only"
                        v-model="onlyWithImages"
                        class="ma-0"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.information.images="{ slotProps }">
          <EntityIndicators :item="slotProps.item"/>
        </template>
      </EntitiesList>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import LoadIndicator from '@/components/common/LoadIndicator';
import EntitiesList from '@/components/entity/EntitiesList';
import EntityIndicators from '../../entity/EntityIndicators';

const { mapGetters } = createNamespacedHelpers('search');

export default {
  components: {
    EntitiesList,
    LoadIndicator,
    EntityIndicators,
  },
  props: {
    items: Array,
  },
  computed: {
    ...mapGetters(['minThreshold', 'maxThreshold', 'isLoading']),
    computedHeaders() {
      // if (this.items?.some((item) => item?.postScore > 0)) {
      //   return [
      //     ...this.headers,
      //     {
      //       text: 'Aggregated score',
      //       value: 'postScore',
      //       align: 'center',
      //     },
      //   ];
      // }
      return this.headers;
    },
    itemDataSlots() {
      return ['information.images'];
    },
  },

  data() {
    return {
      currentThreshold: 0,
      onlyWithImages: false,
      headers: [
        {
          value: 'information.images',
          filter: (value) => (!this.onlyWithImages || (value && value.length)),
          width: '50px',

        },
        {
          text: 'Name',
          value: 'information.names',
          align: 'left',
          width: '25vw',
          // maxHeight: '30px'
        },
        {
          text: 'Id',
          value: 'uniqueEntityId',
          align: 'left',
          width: '10%',
        },
        {
          text: 'Type',
          value: 'entityType',
          align: 'left',
          // width: '10%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'left',
          // width: '10%',
        },
        {
          text: 'Source',
          value: 'sourceId',
          alight: 'left',
          // width: '10%',
        },
        {
          text: 'Score (%)',
          value: 'max_score',
          filter: (value) => value >= (this.currentThreshold - 0.01),
          align: 'left',
          // width: '10%',
        },
        {
          text: 'Status',
          value: 'trackingStatus',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
