import { padLeadingZeros } from '@/utils/dates';

export const createAMLSearchQuery = (searchData) => {
  const {
    isCompany,
    isIndividual,
    isInitials,
    dates,
    selectedBirthYears,
    queryString,
    selectedDataSources,
    selectedCountries,
    threshold,
    mlThreshold,
  } = searchData;

  let _isIndividual;
  if (isCompany === isIndividual) {
    _isIndividual = null;
  } else {
    _isIndividual = isIndividual ? 1 : 0;
  }

  const yearsOfBirthValues = dates ? [dates] : selectedBirthYears;

  const query = {
    names: [queryString],
    isIndividual: _isIndividual,
    isInitials,
    selectedDataSources,

    yearsOfBirth: {
      values: yearsOfBirthValues,
      excludeBlanks: false,
    },
    countries: {
      values: selectedCountries,
      excludeBlanks: false,
    },
    minimumWordsInName: 1,
    threshold,
    mlThreshold,
    responseType: 'minimalResults',
  };

  return query;
};

export const createCRMSearchQuery = (formData) => {
  const {
    name,
    dateOfBirth,
    address,
    phone,
    email,
    searchAddressInBlacklist,
  } = formData;

  const query = {
    names: [name],
    dates: padLeadingZeros(dateOfBirth),
    address,
    phone,
    email,
    addressBlackListSearch: searchAddressInBlacklist,
  };

  return query;
};
