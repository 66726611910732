<template>
  <v-container fluid>
    <LoadIndicator v-model="isLoading" />

    <SearchHeader @search="doSearch" />

    <v-row v-if="this.features.useImageRecognition && !hasLoadedOnce">
      <v-col align="center">
        <ImageRecognition />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { createAMLSearchQuery } from '@/helpers/search';
import { SEARCH_REQUEST } from '@/store/actions/search';
import LoadIndicator from '@/components/common/LoadIndicator';
import ImageRecognition from './ImageRecognition';
import SearchHeader from './SearchHeader';

export default {
  components: {
    LoadIndicator,
    ImageRecognition,
    SearchHeader,
  },

  computed: {
    ...mapGetters({
      features: 'config',
      hasLoadedOnce: 'search/hasLoadedOnce',
      isLoading: 'search/isLoading',
    }),
  },

  methods: {
    ...mapActions({
      loadDataSources: 'READ_LIST_dataSource',
      loadCountries: 'READ_LIST_country',
      searchRequest: `search/${SEARCH_REQUEST}`,
    }),
    doSearch(searchData) {
      if (searchData.queryString?.length > 0) {
        this.$emit('searching');

        const request = createAMLSearchQuery(searchData);
        this.searchRequest(request);
      }
    },
    loadData() {
      this.loadDataSources();
      this.loadCountries();
    },
  },

  created() {
    this.loadData();
  },
};
</script>
