<template>
  <v-container fluid>
    <v-row align="center" justify="center" style="height: 90vh">
      <v-col align="center">
        <v-img src="img/long-logo.svg" width="360px" class="mt-10 mb-10"/>
        <Search @searching="doSearch"/>
        <v-row>
          <v-col align="center">
            <div class="title">Don’t drown in AML regulations</div>
            <div class="subtitle-1">we’ve got you-ensured</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { SEARCH_RESET } from '@/store/actions/search';
import Search from '../components/aml/search/Search';

export default {
  components: {
    Search,
  },
  methods: {
    ...mapActions({ reset: `search/${SEARCH_RESET}` }),
    ...mapActions('nav', ['openDrawer', 'closeDrawer']),
    doSearch() {
      this.$router.push('/query');
      // this.openDrawer();
    },
  },
  mounted() {
    this.reset();
    // this.closeDrawer();
  },
};
</script>

<style scoped>

</style>
