<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <a href="#"> Click here to view detailed query params</a>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mt-3 ml-0 mr-0 mb-3">
<!--          <v-text-field dense readonly label="Names:" :value="request.queryString"-->
<!--                        class="ml-1 mr-1"></v-text-field>-->
          <v-text-field v-if="request.selectedBirthYears.length" dense readonly disabled label="Years:"
                        :value="request.selectedBirthYears" class="ml-1 mr-1"></v-text-field>
          <v-text-field v-if="request.dates" dense readonly disabled label="Exact date:" :value="request.dates"
                        class="ml-1 mr-1"></v-text-field>
          <v-text-field dense readonly disabled label="Individuals:" :value="request.isIndividual"
                        class="ml-1 mr-1"></v-text-field>
          <v-text-field dense readonly disabled label="Companies:" :value="request.isCompany"
                        class="ml-1 mr-1"></v-text-field>
          <v-text-field dense readonly disabled label="Initials:" :value="request.isInitials"
                        class="ml-1 mr-1"></v-text-field>
          <v-row v-if="request.selectedCountries.length">
            <span class="v-subheader">Countries:</span>
            <v-chip-group column>
              <v-chip outlined v-for="country in request.selectedCountries" :key="country">
                {{ country }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-if="request.selectedDataSources.length">
            <span class="v-subheader">Data sources:</span>
            <v-chip-group column>
              <v-chip outlined v-for="datasource in request.selectedDataSources" :key="datasource">
                {{ datasource }}
              </v-chip>
            </v-chip-group>
          </v-row>

          <!--          <v-text-field v-if="request.countries.length" dense readonly disabled aria-multiline="true" label="Countries:"-->
          <!--                        :value="request.countries" class="ml-1 mr-1"></v-text-field>-->
          <!--          <v-text-field v-if="request.selectedDataSources.length" dense readonly disabled label="Data sources:"-->
          <!--                        :value="request.selectedDataSources" class="ml-1 mr-1"></v-text-field>-->
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    request: Object,
  },
};
</script>

<style scoped>

</style>
<!--formData: {-->
<!--isIndividual: true,-->
<!--isCompany: true,-->
<!--isInitials: false,-->
<!--queryString: '',-->
<!--selectedDataSources: [],-->
<!--yearsOfBirth: [],-->
<!--dates: null,-->
<!--countries: [],-->
<!--threshold: 0.4,-->
<!--mlThreshold: 0.7,-->
<!--},-->
