<template>
  <div>
    <v-icon v-if="item.information && item.information.images">badge</v-icon>
  </div>
</template>

<script>
export default {
  props: { item: Object },
};
</script>

<style scoped>

</style>
