<template>
  <v-container fluid>
    <LoadIndicator v-model="ocrInProgress"/>
    <v-row>
      <input v-show="false" type="file" ref="fileUpload" @change="loadImage"/>
    </v-row>
    <v-row style="max-height: 480px; max-width: 640px;">
      <v-col>
        <clipper-basic ref="clipper" :src="imageBlob">
          <template v-slot:placeholder>
            <v-btn color="primary" outlined class="ma-2" @click="loadSample">
              Try sample image
            </v-btn>
              or &nbsp;
            <v-btn outlined class="ma-2" @click="selectForUpload">
              Upload your image
            </v-btn>
          </template>
        </clipper-basic>
      </v-col>
    </v-row>
    <v-btn v-if="imageBlob"
           fixed
           right
           top
           elevation="6"
           @click="recognize"
           class="mt-12"
           color="primary"
    >
      Recognize & Search!
    </v-btn>
  </v-container>
</template>
<script>
import apiClient from '@/services/apiClient';
import LoadIndicator from '@/components/common/LoadIndicator';

export default {
  components: {
    LoadIndicator,
  },
  props: ['value'],
  data() {
    return {
      ocrInProgress: false,
      recognizedResponse: null,
      imageBlob: '',
      croppedImage: '',
      imageFile: null,
    };
  },
  methods: {
    loadSample() {
      this.imageBlob = '/img/sample.jpg';
    },
    selectForUpload() {
      this.$refs.fileUpload.click();
    },

    cropImage() {
      const canvas = this.$refs.clipper.clip();
      this.croppedImage = canvas.toDataURL();
    },

    loadImage(event) {
      this.imageFile = event.target.files.item(0);
      this.imageBlob = URL.createObjectURL(this.imageFile);
    },

    async recognize() {
      this.cropImage();

      this.ocrInProgress = true;
      try {
        this.recognizedResponse = await apiClient.recognizeImage(this.croppedImage);
        this.$emit('input', `${this.recognizedResponse.data[0]}`);
      } catch (err) {
        // todo properly handling
        console.error(err);
      } finally {
        this.ocrInProgress = false;
      }
    },

  },
};
</script>
