<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0">
        <SearchBar
          v-model="searchData.queryString"
          @search="$emit('search', searchData)"
          @toggle-options="toggleShowOptions"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-bottom-sheet v-model="showOptions" inset>
        <SearchOptionsModal
          v-model="searchData"
          @input="updateSearchData"
          @toggle-options="toggleShowOptions"
        />
      </v-bottom-sheet>
    </v-row>

    <!-- appears only after sending a search request -->
    <v-row v-if="searchData" class="pl-md-10 pr-md-10 pb-md-0 pt-0 ma-0">
      <SearchOptionsDisplay v-if='hasLoadedOnce' :request="searchData" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { lifeYearRange } from '@/utils/dates';
import SearchBar from './SearchBar';
import SearchOptionsModal from './SearchOptionsModal';
import SearchOptionsDisplay from './SearchOptionsDisplay';

export default {
  components: {
    SearchBar,
    SearchOptionsModal,
    SearchOptionsDisplay,
  },
  data() {
    return {
      showOptions: false,
      searchData: {
        queryString: '',
        isIndividual: true,
        isCompany: true,
        isInitials: false,
        selectedDataSources: [],
        selectedBirthYears: [],
        selectedCountries: [],
        dates: null,
        threshold: 0.4,
        mlThreshold: 0.7,
      },
    };
  },
  methods: {
    toggleShowOptions() {
      this.showOptions = !this.showOptions;
    },
    updateSearchData(newSearchData) {
      this.searchData = newSearchData;
    },
  },
  computed: {
    ...mapGetters(['dataSourceList', 'countryList']),
    ...mapGetters('search', ['query', 'hasLoadedOnce']),
    years() {
      return lifeYearRange();
    },
  },

  created() {
    const names = this.query?.names;

    if (names && names.length > 0) {
      this.searchData = {
        ...this.searchData,
        queryString: names[0],
      };
    }
  },
};
</script>

<style>
</style>
